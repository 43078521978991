.hs-skill-container {
  width: 80px;
  height: 32px;
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
  border-radius: 2px;
  margin-left: 5px;
  margin-top: 5px;
  display: inline-block;
}

.hs-skill-tooltip {
  width: 21px;
  height: 21px;
  margin: 4px;
  cursor: pointer;
}

.hs-skill-input {
  width: 40px;
  text-align: center;
  vertical-align: middle;
}
