@import "~antd/dist/antd.css";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 32px;
  background-image: url("assets/img/OSRSPlannerLogo.png");
  width: 168px;
}

.ant-layout {
  background: #fff;
}
.site-layout .site-layout-background {
  background: #fff;
  padding: 0px 12px 5px 12px !important;
}

.ant-tree-switcher-icon {
  font-size: 12px !important;
}

.ant-tree-node-content-wrapper {
  cursor: default !important;
}

.content {
  margin: 16px;
}

.highscores {
  flex: 1;
  margin-right: 30px;
}

.quest-requirements {
  max-width: 550px;
  min-width: 350px;
  height: 160px;
  margin: 48px auto 5px auto;

  text-align: center;
}

.table-flex-container {
  display: flex;
  flex: 1;
  flex-flow: row;
}

.master-quest-list {
  margin-right: 30px;
  flex: 1;
}

.ant-divider-horizontal {
  margin-top: 10px !important;
}

@media all and (max-width: 1325px) {
  .ant-divider-horizontal {
    margin-top: 25px !important;
  }
  .flex-container {
    flex-flow: column wrap;
  }

  .table-flex-container {
    flex-flow: column wrap;
  }

  .highscores,
  .master-quest-list {
    margin-right: 0px;
  }

  .quest-requirements {
    display: inline-block;
    margin: 0px;
  }
}
